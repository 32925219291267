<template>

  <div id="loginPage">
    <CommonHeaderLogin/>
    <div style="display: flex;align-content: center;justify-content: center;flex-direction: row;">
      <div style="width: 420px;height: 500px;background-color: #eee;border: solid 0px #ddd;margin-bottom: 50px;">
        <el-carousel height="500px" width="420px">
          <el-carousel-item>
            <img src="../../assets/images/doc.png" style="width:100%; height: 100%;">
          </el-carousel-item>
        </el-carousel>
      </div>
      <div style="width: 356px;height: 436px;padding: 32px;border-top: solid 1px #ddd;margin-bottom: 50px;background-color: #fff;">
        <div style="font-size: 16px;font-weight: bold;width: 100%;text-align: center;" v-if="mode=='l'">会員ログイン</div>
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" style="margin-top: 30px;" v-if="mode=='l'">
          <el-form-item label="" prop="username">
            <el-input
              placeholder="ユーザー名"
              v-model="ruleForm.username"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="" prop="password">
            <el-input
              placeholder="パスワード"
              v-model="ruleForm.password"
              show-password
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="" prop="keepalive">
            <el-checkbox v-model="ruleForm.keepalive" label="1">30日で自動ログイン</el-checkbox>
          </el-form-item>
        
          <el-form-item style="text-align:center;">
            <el-button
              type="primary"
              @click="submitRuleForm('ruleForm')"
              style="width:100%" 
              >ログイン</el-button
            >
            <div class="forgetPassword">
              <el-link :underline="false" @click="mode='f'">
                パスワード忘れた
              </el-link></div>
          </el-form-item>
          <el-form-item style="text-align:center;">
            <el-button
              @click="register"
              style="width:100%" 
              >試してみる</el-button
            >
          </el-form-item>
        </el-form>

        <div style="font-size: 16px;font-weight: bold;width: 100%;text-align: center;" v-if="mode=='r'">{{$t('login.regist')}}</div>
        <el-form :model="form" label-width="80px" style="margin-top: 30px;" label-position="left" v-show="mode=='r'" ref="form" :rules="rulesRegist">
          <el-form-item label="名前" prop="rusername">
            <el-input v-model="form.rusername" maxlength="50" ></el-input>
          </el-form-item>
          <el-form-item label="会社名" prop="rcompanyName">
            <el-input v-model="form.rcompanyName" maxlength="50" ></el-input>
          </el-form-item>
          <el-form-item label="メール" prop="remail">
            <el-input v-model="form.remail" maxlength="50" ></el-input>
          </el-form-item>
          <el-form-item label="携帯" prop="rmobile">
            <el-input v-model="form.rmobile" maxlength="50" ></el-input>
          </el-form-item>
          <el-form-item style="text-align:center;">
            <template slot="">
              <el-button type="primary" @click="handleRegister" style="width:100%" >申し込み</el-button>
            </template>
          </el-form-item>
          <el-form-item style="text-align:center;" >
            <el-button @click="goLogin" style="width:100%">戻る</el-button>
          </el-form-item>
        </el-form>

        <div style="font-size: 16px;font-weight: bold;width: 100%;text-align: center;" v-if="mode=='f'">{{$t('login.forgetPwd')}}</div>
        <el-form :model="pform" label-width="80px" style="margin-top: 30px;" label-position="left" v-show="mode=='f'" ref="pform" :rules="rulesPassword">
          <el-form-item label="メール" prop="email">
            <el-input v-model="pform.email" maxlength="50" ></el-input>
          </el-form-item>
          <el-form-item style="text-align:center;">
            <el-button type="primary" @click="handleForget" style="width:100%" >送信</el-button>
          </el-form-item>
          <el-form-item style="text-align:center;" >
            <el-button @click="goLogin" style="width:100%">戻る</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    
    <footer id="footer">
      Enworkspace.com&nbsp;©️2024 EnLinkage Co.,ltd All Rights Reserved.
      Powered By <a style="color:#fff" href="http://www.enable-software.com/">Enable</a>
    </footer>
  </div>
</template>

<script>
import CommonHeaderLogin from "@/components/common/CommonHeaderLogin.vue";
import { login,register,resetPWD } from "@/api/account";
import { mapState, mapMutations } from "vuex";
import { mutationsType } from "@/store/mutations-type.js";
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
import { isNullOrUndefinedOrEmpty } from "@/utils/stringHelper.js";
import { ROUTER_PATH } from "@/constants/common.js";
import { isValidResponse } from "@/utils/commonUtils";
//import CookieUtil from "@/utils/cookie.js";


export default {
  name: "Login",
  components: {
    CommonHeaderLogin,
  },
  data: () => {
    //var e1 = this.$t('err1');
    //var e2 = this.$t('err2');
    return {
      ROUTER_PATH: ROUTER_PATH,
      ruleForm: {
        username: "",
        password: ""
      },
      show: true,
      lang: "",
      rules: {
        username: [
          { required: true, message: "ユーザー名を入力してだくさい", trigger: "blur" }
        ],
        password: [
          { required: true, message: "パスワードを入力してだくさい", trigger: "blur" }]
      },
      rulesRegist: {
        "rusername": [
          { required: true, message: "ユーザー名を入力してだくさい", trigger: "blur" }
        ],
        "rcompanyName": [
          { required: true, message: "パスワードを入力してだくさい", trigger: "blur" }
        ],
        "remail": [
          { required: true, message: "メールを入力してだくさい", trigger: "blur" }
        ],
        "rmobile": [
          { required: true, message: "携帯を入力してだくさい", trigger: "blur" }
        ]
      },
      rulesPassword: {
        email: [
          { required: true, message: "メールを入力してだくさい", trigger: "blur" }]
      },
      form:{},
      pform:{},
      dialogFormVisible: false,
      mode:"l"
    }
    
  },
  mounted() {
    //this.lang = CookieUtil.getCookie('lang') == null ? 'zh' : CookieUtil.getCookie('lang');
    this.lang = "日本語";
    this.$i18n.locale = "ja";
    if (
      !isNullOrUndefinedOrEmpty(
        localStorage.getItem(LOCAL_STORAGE_KEY.RPA_TOKEN)
      ) &&
      !isNullOrUndefinedOrEmpty(
        localStorage.getItem(LOCAL_STORAGE_KEY.RPA_USER_ID)
      )
    ) {
      this.$router.push(ROUTER_PATH.HOME);
    }

    // if((navigator.language || navigator.browserLanguage).toLowerCase().includes('zh')) {
    //     this.$cookies.set("language", "zh", 60 * 60 * 24 * 30);
    //     this.$i18n.locale = "zh";
    //     this.lang = "中文";
    //  } else if((navigator.language || navigator.browserLanguage).toLowerCase().includes('ja')) {
        this.$cookies.set("language", "ja", 60 * 60 * 24 * 30);
        this.$i18n.locale = "ja";
        this.lang = "日本語";
    //}
    
  },
  methods: {
    ...mapMutations([mutationsType.INIT_ACCOUNT_INFO]),
    submitRuleForm(ruleFormName) {
      this.$refs[ruleFormName].validate(valid => {
        if (valid) {
          login({
            ...this.ruleForm
          }).then(response => {
            if (response.code == "000") {
              this.$message({
                type: "success",
                message: "ログインが成功しました"
              });
              localStorage.setItem(
                LOCAL_STORAGE_KEY.RPA_TOKEN,
                response.data.accessToken
              );
              localStorage.setItem(
                LOCAL_STORAGE_KEY.RPA_USER_ID,
                response.data.id
              );
              if (this.ruleForm.keepalive) {
                localStorage.setItem(
                  LOCAL_STORAGE_KEY.RPA_TOKEN_EXPIRE,
                  Date.now() + 30*24*60*60*1000
                );
              } else {
                localStorage.setItem(
                  LOCAL_STORAGE_KEY.RPA_TOKEN_EXPIRE,
                  Date.now() + 24*60*60*1000
                );
              }
              

              this.$router.push("/kintone/Top");

            } else {

              this.$message({
                type: "warning",
                message: "ユーザー名若しくはパスワードが不正です"
              });
            }
            

            // this.$router.push(
            //   isNullOrUndefinedOrEmpty(this.loginPrePath)
            //     ? ROUTER_PATH.HOME
            //     : this.loginPrePath
            // );
          });
        } else {
          return false;
        }
      });
    },
    register() {
      this.mode = "r";
      this.form = {};
    },
    handleRegister(){
      this.$refs["form"].validate(valid => {
        if (valid) {
          register({
            username: this.form.rusername,
            companyName: this.form.rcompanyName,
            email: this.form.remail,
            mobile: this.form.rmobile,
          }).then(response => {
            if(isValidResponse(response)){
              this.$message({
                message: "お試しを申請しました",
                type: "success"
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    handleForget(){
      this.$refs["pform"].validate(valid => {
        if (valid) {
          resetPWD({
            ...this.form
          }).then(response => {
            if(isValidResponse(response)){
              this.$message({
                message: "送信しました",
                type: "success"
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    goLogin(){
      this.mode = "l";
      this.ruleForm = {};
      this.$refs["ruleForm"].resetField();
    }
    // handleCommand(command) {
    //   if (command == "zh") {
    //     this.lang = "中文";
    //     this.$i18n.locale = "zh";
    //     //this.$cookie.set("lang","zh");
    //   } else {
    //     this.lang = "日本語";
    //     this.$i18n.locale = "ja";
    //     //this.$cookie.set("lang","jp");
    //   }
    // }
    

  },
  computed: {
    // rules() {
    //   return {
    //     username: [
    //       { required: true, message: this.$t('login.err1'), trigger: "blur" }
    //     ],
    //     password: [
    //       { required: true, message: this.$t('login.err2'), trigger: "blur" }]
    //   }
    // },
    ...mapState(["loginPrePath"])
  }
};
</script>

<style scoped>
#loginPage {
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: #F2F6FC;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#footer { 
  width:100%;
  text-align: center;
  line-height: 55px;
  height: 55px;
  background-color: #333a40;
  color: #fff;
  font-size: 12px;
  margin-top: 36px;
  position: absolute;
  bottom: 0;
  left: 0;
}


</style>
