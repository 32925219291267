<template>
  <header id="header">
    <div class="container">
      <img src="../../assets/images/logo.png" width="120" />
      <nav>
        &nbsp;
      </nav>
      <HeaderRightNav />
    </div>
  </header>
</template>

<script>
import HeaderRightNav from "./HeaderRightNav.vue";
import { isNullOrUndefinedOrEmpty } from "@/utils/stringHelper.js";
import { getUserInfo } from "@/api/account";
import { mapState, mapMutations } from "vuex";
import { mutationsType } from "@/store/mutations-type.js";
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
export default {
  name: "CommonHeaderLogin",
  components: { HeaderRightNav },
  mounted() {
    
  },
  computed: {
  },
  methods: {
  }
};
</script>

<style scoped>
#header {
  z-index: 1;
  height: 60px;
  flex-shrink:0;
  width: 840px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;  
}
#header .container {
  width: 840px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
}

#header nav {
  flex: 1;
  margin-left: 50px;
}
</style>
